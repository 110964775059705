import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  api:string;
  constructor(
    private apiConfig: HttpConfigService,
  ) {
      this.api = this.apiConfig.getBaseUrl + 'cms';
    }

    getAllCMSPages(): Observable<any> {
      return this.apiConfig.get(this.api);
    }
  
    getCMSPageByTitle(title:string): Observable<any> {
      return this.apiConfig.get(this.api + '/' + title);
    }

}
